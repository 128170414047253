<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <bullet :title="'List Akun Karyawan'" />
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end">
            <div class="col_tollbar">
              <v-btn
                depressed
                class="text-capitalize btn_tollbar rounded-lg bg_color1"
                dark
                :to="`/admin/mk/client-employee/employee/${this.$route.params.idClient}/create`"
              >
                <v-icon class="mr-2"> mdi-plus</v-icon> Tambah
              </v-btn>
            </div>
            <div class="col_tollbar">
              <v-menu
                :close-on-content-click="false"
                v-model="sort_menu"
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    dark
                    color="#CFE1E1"
                    class="text-capitalize btn_tollbar rounded-lg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#106967"> {{ icon_sort }}</v-icon>
                    <h4 class="ml-2" style="color: #106967">
                      {{
                        sort
                          ? sort == "tgl_masuk"
                            ? "Tanggal Masuk"
                            : sort == "gender"
                            ? "Jenis Kelamin"
                            : "Posisi"
                          : "Sort"
                      }}
                    </h4>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <div class="d-flex align-center">
                      <v-btn
                        @click="
                          changedesc(
                            icon_sort == 'mdi-sort-ascending'
                              ? 'mdi-sort-descending'
                              : 'mdi-sort-ascending'
                          )
                        "
                        block
                        class="text-capitalize"
                        text
                      >
                        <v-icon class="mr-2">{{ icon_sort }}</v-icon>
                        Sort
                      </v-btn>
                    </div>
                    <v-btn @click="sort_menu = false" icon fab x-small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'tgl_masuk'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'tgl_masuk' ? true : false"
                      text
                      @click="changeSort('tgl_masuk')"
                    >
                      Tanggal Masuk
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'jenis_kelamin'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'jenis_kelamin' ? true : false"
                      text
                      @click="changeSort('jenis_kelamin')"
                    >
                      Jenis Kelamin
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      :class="
                        sort == 'jabatan'
                          ? 'text-capitalize grey'
                          : 'text-capitalize white'
                      "
                      :dark="sort == 'jabatan' ? true : false"
                      text
                      @click="changeSort('jabatan')"
                    >
                      Posisi
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-card flat class="card_on_view">
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          class="elevation-0"
        >
          <template v-slot:[`item.user.status`]="{ item }">
            <v-card
              v-if="item.user"
              flat
              class="px-3 py-1 rounded-lg"
              :color="item.user.status == '1' ? '#26AF65' : '#DC3545'"
            >
              <p class="pa-0 ma-0" style="color: white; text-align: center">
                {{ item.user.status == "1" ? "Aktif" : "Non-Aktif" }}
              </p>
            </v-card>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    @click="sendEmailVerification(item)"
                  >
                    <v-icon dark small> mdi-account-reactivate </v-icon>
                  </v-btn>
                </template>
                <span>Kirim Ulang Verifikasi Akun</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    @click="toDetail(item)"
                  >
                    <v-icon dark small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Lihat Detail</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    color="red"
                    @click="deleteData(item)"
                  >
                    <v-icon dark small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Hapus Karyawan</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>
    </v-card>
    <div class="pa-2">
      <dialog-small
        :d_small="d_small"
        @refetch="fetchData"
        @close="d_small = false"
        :inventory="inventory"
        :type="d_type"
        :title="d_title"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  name: "listEmployee",
  components: { bullet, NavbarProfile },
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      menuLimit: false,
      sort_menu: false,
      loading: false,
      dataSet: null,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      page: 1,
      limit: 10,
      last_page: null,
      total_page: null,
      find: "",
      sort: "",
      icon_sort: "mdi-sort-ascending",
      headers: [
        { text: "Nama", value: "nama" },
        { text: "Tanggal Masuk", value: "tgl_masuk" },
        { text: "Posisi", value: "jabatan" },
        { text: "Jenis Kelamin", value: "jenis_kelamin" },
        { text: "Nomor Telepon", value: "telepon" },
        { text: "Email", value: "user.email" },
        { text: "Status", value: "user.status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Akun Karyawan",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // masuk ke detail karyawan
    toDetail(item) {
      this.$router.push(
        `/admin/mk/client/employee/${this.$route.params.idClient}/update/${item.id}`
      );
    },
    // delete karyawan
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/karyawan/akun/delete`,
        deleteType: "Karyawan",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Karyawan";
      this.d_small = true;
      this.line = true;
    },

    // change sorting berdasarkan
    changeSort(item) {
      this.sort = item;
      this.sort_menu = false;
      this.fetchData();
    },

    // change sorting terlama atau terbaru
    changedesc(item) {
      this.icon_sort = item;
      this.fetchData();
    },

    // kirim email verifikasi ke email karyawan
    sendEmailVerification(item) {
      console.log(item);
      let data = {
        body: item,
        path: `sysadmin/user/akun/resend`,
      };
      this.$store
        .dispatch("userClient/postApi", data)
        .then((data) => {
          this.$store.dispatch("notify", {
            msg: data.message,
            type: "success",
          });
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    // get list karyawan
    fetchData() {
      this.loading = true;
      console.log(this.role);
      let path = `sysadmin/karyawan/akun/list?page[size]=${this.limit}&page[number]=${this.page}&filter[klien_id]=${this.$route.params.idClient
        }&sort=${this.sort ? (this.icon_sort == "mdi-sort-ascending" ? "-" : "+") : ""}${this.sort}&filter[nama]=${this.find}`;

        if(this.role == 'ACCOUNTOFFICER'){
          path = `sysadmin/karyawan/akun/list?page[size]=${this.limit}&page[number]=${this.page}&sort=${this.sort ? (this.icon_sort == "mdi-sort-ascending" ? "-" : "+") : ""}${this.sort}&filter[nama]=${this.find}`;
        }
      let data = {
        path: path,
      };
      this.$store
        .dispatch("userClient/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },

    // ubah limit data perpage
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.v-pagination__item {
  box-shadow: none !important;
}
.v-pagination__navigation {
  box-shadow: none !important;
}
.grey {
  background: gray !important;
}
</style>
